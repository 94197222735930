@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  /* height: 100vh; */
  text-align: center;
  background-attachment: fixed;
  /* background-image: url(https://images.unsplash.com/photo-1449057528837-7ca097b3520c?crop=entropy&fit=crop&fm=jpg&h=1325&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=2500); */
}

.title {
  font-weight: 400;
}

.box {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: solid 1px rgb(250, 249, 249);
  border-radius: 3px;
  box-shadow: 0 0 10px;
  margin-left: auto;
  margin-right: auto;
}
.configuration {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
}
.configurationLeftmiddle {
  width: 70%;
  display: flex;
  flex-direction: row;
}
.configuration-left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  margin: 5px 10px;
}
.label {
  font-size: 14px;
  font-weight: 600;
}
.configuration-middle {
  width: 50%;
  margin: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

}

.configuration-right {
  width: 30%;
  margin: 5px auto;
  align-items: center;
  margin-right: 20px;
}
.textfield {
  padding-right: 0;
}

.hide {
  display: flex;
  flex-direction: row;
}

.inputTable {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.tree-charac {
  margin-left: auto;
  margin-right: auto;
}

/* disable arrows from Number Input */
/* For chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* For firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 576px) {
  .title {
    font-size: 1.5rem;
  }
  .configuration {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }
  .configurationLeftmiddle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .configuration-left {
    width: 90%;
  }
  .configuration-middle {
    margin-top: 0;
    width: 90%;
  }
  .configuration-right {
    width: 90%;
    margin-top: 0;
    margin-left: 0;

  }

  .hide {
    width: 80%;
    flex-direction: column;
  }
}

@media screen and (min-width: 577px) and (max-width: 977px) {
  .configuration {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    /* align-items: center; */
  }
  .configurationLeftmiddle {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .configuration-left {
    width: 50%;
  }
  .configuration-middle {
    width: 50%;
  }

  .configuration-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0;
    justify-content: space-around;
  }

  .right {
    flex-basis: 46%;
  }

  .hide {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

@media only screen and (min-width: 978px) and (max-width: 1100px) {
  .configuration {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: row;
  }
  .configurationLeftmiddle {
    width: 70%;
    display: flex;
    flex-direction: row;
  }
  .configuration-left {
    width: 50%;
  }
  .configuration-middle {
    width: 50%;
  }
  .configuration-right {
    width: 28%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  /* .label {
    font-size: 11px;
  } */

  .hide {
    width: 80%;
    flex-direction: column;
  }
}

.btn-group {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.btn-group1 {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.btn-group2 {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.btn {
  font-size: 14px;
}

@media only screen and (max-width: 587px) {
  .btn-group {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn-group1 {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn-group2 {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn {
    width: 120px;
    font-size: 8px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

