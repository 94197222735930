.btn-group {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.btn-group1 {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.btn-group2 {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.btn {
  font-size: 14px;
}

@media only screen and (max-width: 587px) {
  .btn-group {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn-group1 {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn-group2 {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn {
    width: 120px;
    font-size: 8px;
    height: fit-content;
  }
}
